import { FC, ReactElement, useEffect } from "react"

type ChildProps = {
    onSubmit: any,
    ignoreAlert: any,
}

const PasswordWillExpire: FC<ChildProps> = ({ onSubmit, ignoreAlert }): ReactElement => {

    /**
     * Called on Init
     */
    useEffect(() => {
    }, [])

    return (
        <form className="form">
            <div className="form-footer">
                <div className="left-side">
                    <button className="btn btn-link" type="button" onClick={ignoreAlert}>Ignorer et continuer</button>
                </div>
                <div className="right-side">
                    <button className="btn btn-primary" type="button" onClick={onSubmit}>Mettre à jour</button>
                </div>
            </div>
        </form>

    )
}

export default PasswordWillExpire