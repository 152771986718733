import IRoute from '../interfaces/route.interface';
import HomePage from '../pages/home/home';

const routes: IRoute[] = [
    {
        path: '/',
        name: 'Connexion',
        component: HomePage,
        exact: true
    }
]

export default routes