import { ErrorResponseType } from "../enums/error-response-type";
import { WarningResponseType } from "../enums/warning-response-type";
import { TextMessages } from "../variables/text-messages";

export function GetErrorOrWarningText(error: ErrorResponseType | WarningResponseType): string {
    switch (error) {
        case ErrorResponseType.BAD_LOGIN:
            return TextMessages.error.BAD_LOGIN
        case ErrorResponseType.MUST_CHANGE_PASSWORD:
            return TextMessages.error.MUST_CHANGE_PASSWORD
        case ErrorResponseType.PASSWORD_EXPIRED:
            return TextMessages.error.PASSWORD_EXPIRED
        case WarningResponseType.SHOULD_CHANGE_PASSWORD:
            return TextMessages.warning.SHOULD_CHANGE_PASSWORD
        default:
            return TextMessages.error.BAD_LOGIN
    }
}