import React, { FC, ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ILogin } from "../../interfaces/data-requests/login.interface";

type ChildProps = {
    onSubmit: any,
    onRecoverPasswordClick: any,
    initForm: ILogin
}

const LoginForm: FC<ChildProps> = ({ onSubmit, onRecoverPasswordClick, initForm }): ReactElement => {
    /**STATE */
    const { register, handleSubmit, errors, reset } = useForm<ILogin>();
    const [loginForm, setLoginForm] = useState<ILogin>({
        username: '',
        password: ''
    })

    /**
     * Called on Init and when 'initForm' changes
     */
    useEffect(() => {
        reset(initForm)
        setLoginForm(initForm)
    }, [initForm, reset]);

    /**
     * sets username form prop
     * @param e 
     */
    const handleUsernameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setLoginForm({ ...loginForm, username: e.currentTarget.value })
    }

    /**
     * sets password form prop
     * @param e 
     */
    const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
        setLoginForm({ ...loginForm, password: e.currentTarget.value })
    }

    /**
     * triggers Recover Password
     */
    const onRecoverPassword = () => {
        onRecoverPasswordClick(true)
    }

    /** VIEW RENDER */
    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="username">Nom d'utilisateur</label>
            <input autoFocus
                id="username"
                type="text"
                aria-invalid={errors.username ? "true" : "false"}
                name="username"
                ref={register({ required: true })}
                onChange={handleUsernameChange}
            />
            {errors.username &&
                <div className="form-alerts" role="alert">
                    Nom d'utilisateur requis
                    </div>
            }
            <label htmlFor="password">Mot de passe</label>
            <input
                id="password"
                type="password"
                aria-invalid={errors.password ? "true" : "false"}
                name="password"
                ref={register({ required: true })}
                onChange={handlePasswordChange}
            />
            {errors.password &&
                <div className="form-alerts" role="alert">
                    Mot de passe requis
                    </div>
            }

            <br />
            <div className="form-footer">
                <div className="left-side">
                    <button className="btn btn-link" type="button" onClick={onRecoverPassword}>Mot de passe oublié ?</button>
                </div>
                <div className="right-side">
                    <button className="btn btn-primary" disabled={!loginForm.password || !loginForm.username} type="submit">Se connecter</button>
                </div>
            </div>
        </form>
    )

}

export default LoginForm