import { FC, ReactElement, useEffect } from "react"
import classes from "./loading-bar.module.css";

type ChildProps = {
    isLoading: boolean,
}

const LoadingBar: FC<ChildProps> = ({ isLoading }): ReactElement => {
    useEffect((): void => {
    }, [isLoading])
    return (
        isLoading ?
            <div >
                <span className={classes['bar-animation']}></span>
            </div>
            : <div />
    )
}

export default LoadingBar