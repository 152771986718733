import { ReactElement } from "react";
import classes from './legal-infos.module.css'
import { version } from '../../../package.json'

const LegalInfos = (): ReactElement => {
    console.log("version: ", version)
    return (
        <div className={classes['legal-infos']}>
            <div>
                <p>
                    <a href="https://www.logitud.fr/mentions-legales" rel="noopener noreferrer" target="_blank">Mentions légales</a>   |   <a href="https://www.logitud.fr/" rel="noopener noreferrer" target="_blank">Logitud.fr</a>

                </p>

            </div>
            <div>


            </div>
            <div>
                <p>
                    © Logitud {new Date().getFullYear()} | Tous droits réservés 
                    {/* | {version} */}
                </p>
            </div>
        </div>
    )
}

export default LegalInfos