export const TextMessages = {
    success: {
        SUCCESS_ON_PASWORD_CHANGE: "Le mot de passe a été modifié avec succès",
        SUCCESS_ON_PASSWORD_RECOVER: "Un mail vous a été envoyé afin de réinitialiser votre mot de passe"
    },
    warning: {
        SHOULD_CHANGE_PASSWORD: "Le mot de passe n'est pas conforme à la politique de sécurité"
    },
    error: {
        USER_NOT_FOUND: "Utilisateur introuvable",
        SERVER_HOST_URL_NOT_FOUND: "Erreur lors de la connexion avec le serveur",
        SERVER_NOT_AVAILABLE: "Serveur indisponible",
        BAD_LOGIN: "Nom d'utilisateur ou mot de passe incorrect", //"Erreur d'authentification"
        PASSWORD_EXPIRED: "Votre mot de passe a expiré. Veuillez en saisir un nouveau",
        MUST_CHANGE_PASSWORD: "Votre mot de passe n'est plus valide. Veuillez en saisir un nouveau",
        NOT_AUTHORIZED_ACTION: "Vous n'êtes pas autorisé à effectuer cette opération",
        PASSWORD_CHANGE_FAIL: "Erreur lors de la modification du mot de passe",
        PASSWORD_RECOVER_FAIL: "Erreur lors de la récupération du mot de passe",
        USER_NOT_FOUND_ENGLISH: "Unable to find person"
    }
}