import { TextMessages } from "../variables/text-messages";

export function recoverPasswordResponse(status: number): string {
    let message: string;
    switch (status) {
        case 0:
            message = TextMessages.error.SERVER_NOT_AVAILABLE;
            break;
        case 200:
            message = TextMessages.success.SUCCESS_ON_PASSWORD_RECOVER;
            break;
        case 409:
            message = TextMessages.error.USER_NOT_FOUND;
            break;
        default:
            message = TextMessages.error.PASSWORD_RECOVER_FAIL;
    }
    return message
}

export function translateMessage(message: string): string {
    switch (message) {
        case TextMessages.error.USER_NOT_FOUND_ENGLISH:
            message = TextMessages.error.USER_NOT_FOUND;
            break;
        default:
            return message
    }
    return message
}