import { FC, ReactElement, useEffect, useState } from "react";
import { AlertLevel } from "../../enums/alert";
import { IAlert } from "../../interfaces/alerts/alert.interface";
import classes from './alert.module.css'
import { CheckCircleIcon, IssueOpenedIcon } from '@primer/octicons-react'

type ChildProps = {
    alert: IAlert<string | Array<string>>;
}

const Alert: FC<ChildProps> = ({ alert }): ReactElement => {
    const [messageList] = useState<Array<ReactElement>>([])
    /**
     * Called on Init
     */
    useEffect(() => {
    }, [])

    const renderSwitch = (level: AlertLevel): string => {
        switch (level) {
            case AlertLevel.ERROR:
                return 'error-message';
            case AlertLevel.WARNING:
                return 'warning-message';
            case AlertLevel.INFO:
                return 'info-message';
            case AlertLevel.SUCCESS:
                return 'success-message';
            default:
                return 'error-message';
        }
    }

    /**
     * Returns HTML Element of a list of messages (each message in a div)
     * @param list 
     * @returns 
     */
    const listOfMessages = (list: string[]): ReactElement => {
        for (let i = 0; i < list.length; i++) {
            messageList.push(<div className={classes.text}>{list[i]}</div>)
        };
        return (<div className={classes.text}>
            {messageList}
        </div>
        )

    }

    return (
        <div className={`${classes.alert} ${classes[renderSwitch(alert.level)]} ${classes.flex}`}>
            <div className={classes["icon-side"]}>
                {alert.level === AlertLevel.SUCCESS ? <CheckCircleIcon size={16} /> : <IssueOpenedIcon size={16} />}
            </div>
            <div className={classes["text-side"]}>
                {
                    typeof alert.message === "string" ?
                        alert.message : listOfMessages(alert.message)
                }
            </div>
        </div>
    )
}

export default Alert