import React, { FC, ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IChangePassword } from "../../interfaces/data-requests/change-password.interface";

type ChildProps = {
    onSubmit: any;
    cancelChangePassword: any;
    initForm: IChangePassword;
}

const ChangePasswordForm: FC<ChildProps> = ({ onSubmit, cancelChangePassword, initForm }): ReactElement => {
    /**STATE */
    const { register, handleSubmit, errors, reset } = useForm<IChangePassword>();
    const [changePasswordForm, setChangePasswordForm] = useState<IChangePassword>({
        username: '',
        password: '',
        new_password: '',
        confirmNewPassword: ''
    })

    /**
     * Called on Init and when 'initForm' changes
     */
    useEffect(() => {
        reset(initForm)
        setChangePasswordForm(initForm)
    }, [initForm, reset]);

    /**
     * sets newPassword form prop
     * @param e 
     */
    const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
        setChangePasswordForm({ ...changePasswordForm, new_password: e.currentTarget.value })
    }

    /**
     * sets confirmNewPassword form prop
     * @param e 
     */
    const handleConfirmPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
        setChangePasswordForm({ ...changePasswordForm, confirmNewPassword: e.currentTarget.value })
    }

    /** VIEW RENDER */
    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="new_password">Nouveau mot de passe</label>
            <input
                id="new_password"
                type="password"
                aria-invalid={errors.new_password ? "true" : "false"}
                name="new_password"
                ref={register({ required: true })}
                onChange={handlePasswordChange}
            />
            {errors.new_password && (
                <div className="form-alerts" role="alert">
                    Mot de passe requis
                </div>
            )
            }
            <label htmlFor="confirmNewPassword">Validation du nouveau mot de passe</label>
            <input
                id="confirmNewPassword"
                type="password"
                aria-invalid={errors.confirmNewPassword ? "true" : "false"}
                name="confirmNewPassword"
                ref={register({ required: true })}
                onChange={handleConfirmPasswordChange}
            />
            {errors.confirmNewPassword && (
                <div className="form-alerts" role="alert">
                    Validation du nouveau mot de passe requise
                </div>
            )
            }

            <br />
            <div className="form-footer">
                <div className="left-side">
                    <button className="btn btn-link" type="button" onClick={cancelChangePassword}>Annuler</button>
                </div>
                <div className="right-side">
                    <button className="btn btn-primary" disabled={!changePasswordForm.new_password || !changePasswordForm.confirmNewPassword || (changePasswordForm.new_password !== changePasswordForm.confirmNewPassword)} type="submit">Envoyer</button>
                </div>
            </div>
        </form>
    )

}

export default ChangePasswordForm