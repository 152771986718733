import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import LoginForm from '../../components/login-form/login-form';
import ViewSlider from 'react-view-slider'
import { ILogin } from '../../interfaces/data-requests/login.interface';
import { IRecoverPassword } from '../../interfaces/data-requests/recover-password.interface';
import { IChangePassword } from '../../interfaces/data-requests/change-password.interface';
import ChangePasswordForm from '../../components/change-password/change-password';
import RecoverPasswordForm from '../../components/recover-password/recover-password';
import classes from './home.module.css'
import LoadingBar from '../../components/loading-bar/loading-bar';
import { IAlert } from '../../interfaces/alerts/alert.interface';
import { useHistory } from "react-router-dom";
import Alert from '../../components/alert/alert';
import { AuthService } from '../../services/auth-service';
import LegalInfos from '../../components/legal-infos/legal-infos';
import { formHeaderText } from '../../variables/form-header-text';
import Label from '../../components/label/lable';
import { AlertLevel } from '../../enums/alert';
import { GetErrorOrWarningText } from '../../utils/error-type-handler';
import { IErrorResponse, IMessageAndIsProperty } from '../../interfaces/server-response/error-response.interface';
import { IsErrorResponseType } from '../../enums/is-error-response-type';
import { ErrorResponseType } from '../../enums/error-response-type';
import { recoverPasswordResponse, translateMessage } from '../../utils/recover-password-response';
import { changePasswordResponse } from '../../utils/change-password-response';
import PasswordWillExpire from '../../components/password-will-expire/password-will-expire';
import { SecondViewType } from '../../enums/second-view-type';
import { ILoginSuccessResponse } from '../../interfaces/server-response/success-response.interface';
import { WarningResponseType } from '../../enums/warning-response-type';
import { TextMessages } from '../../variables/text-messages';


const HomePage: FC<void> = (props: any): ReactElement => {
    const history = useHistory()
    const searchParams = props.location.search.substring(1); /** Remove quetionmark */
    /**STATE */
    const [authService] = useState(() => new AuthService());
    const [loginForm] = useState<ILogin>({ password: "", username: "" })
    const [recoverPasswordForm] = useState<IRecoverPassword>({ username: "" })
    const [changePasswordForm] = useState<IChangePassword>({ password: "", username: "", new_password: "", confirmNewPassword: "" })
    const [viewIndex, setViewIndex] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isViewDisabled, setIsViewDisabled] = useState<boolean>(false)
    const [alert, setAlert] = useState<IAlert<string | Array<string>> | null>()
    const [loginSubmitedUser, setLoginSubmitedUser] = useState<ILogin>()
    const [secondPage, setSecondPage] = useState<SecondViewType>(SecondViewType.RECOVER_PASSWORD)

    /**
     * Logout
     */
    const logout = useCallback(() => {
        authService.logout(props.customer)
            .catch((status: number) => {
                if (status === 502) {
                    setAlert({ level: AlertLevel.ERROR, message: TextMessages.error.SERVER_NOT_AVAILABLE })
                    setIsViewDisabled(true)
                }
            })
    }, [authService, props.customer])

    /**
     * Called once on Init
     */
    useEffect((): void => {
        if (!process.env.REACT_APP_HOST_URL) {
            setAlert({ level: AlertLevel.ERROR, message: TextMessages.error.SERVER_HOST_URL_NOT_FOUND })
            setIsViewDisabled(true)
        }
        logout() /** CALL LOGOUT FUNCTION FIRST */

        /** Gets alert and parses it as an object */
        function parseURLParams(): void {
            const urlParams = new URLSearchParams(window.location.search);
            let levelQuery = urlParams.get('level');
            let messageQuery = urlParams.get('message');
            if (levelQuery && messageQuery) {
                try {
                    levelQuery = levelQuery.toUpperCase()
                    const alert: IAlert<string> = { level: levelQuery as AlertLevel, message: messageQuery }
                    setAlert(alert)
                } catch (error) {
                    console.log(error)
                }
            }

        }
        /** Remove URL search params */
        function cleanURLParams(): void {
            history.replace({
                search: '',
            })
        }
        parseURLParams()
        cleanURLParams()
    }, [history, searchParams, logout])


    /**
     * On Login Submit
     * @param data
     */
    const onLoginSubmit = (data: ILogin): void => {
        setLoginSubmitedUser(data);
        setAlert(null)
        setIsLoading(true)
        authService.login(data, props.customer)
            .then((loginSuccess: ILoginSuccessResponse) => {
                setIsLoading(false)
                if (loginSuccess.warning && loginSuccess.warning === WarningResponseType.SHOULD_CHANGE_PASSWORD) {
                    goToSecondView(SecondViewType.PASSWORD_WILL_CHANGE, loginSuccess.note ? { level: AlertLevel.WARNING, message: loginSuccess.note } : null)
                } else {
                    redirectToApplication();
                }
            })
            .catch((err: IErrorResponse<IMessageAndIsProperty>) => {
                setIsLoading(false)
                if (err?.body && err.body.is === IsErrorResponseType.error) {
                    const newAlert: IAlert<string> = {
                        level: AlertLevel.ERROR,
                        message: GetErrorOrWarningText(err.body.msg)
                    }
                    if (err.body.msg === ErrorResponseType.MUST_CHANGE_PASSWORD || err.body.msg === ErrorResponseType.PASSWORD_EXPIRED) {
                        goToSecondView(SecondViewType.CHANGE_PASSWORD, newAlert)
                    } else {
                        setAlert(newAlert)
                    }
                } else if (err.status && !err.body) {
                    setAlert({
                        level: AlertLevel.ERROR,
                        message: TextMessages.error.SERVER_NOT_AVAILABLE
                    })
                } else {
                    setAlert({
                        level: AlertLevel.ERROR,
                        message: TextMessages.error.BAD_LOGIN
                    })
                }
            });
    }

    const redirectToApplication = () => {
        document.location.href = (props.appName === 'decisionnel') ?
            `${document.location.origin}/decisionnel/?customer=${props.customer}`
            : `${document.location.origin}${props.baseHref.substring(0, document.location.pathname.indexOf('/signin') + 1)}`
    }

    /**
     * On Recover Password Submit
     * @param data 
     */
    const onRecoverPasswordSubmit = (data: IRecoverPassword): void => {
        setAlert(null)
        setIsLoading(true)
        authService.recoverPassword(data, props.customer)
            .then(() => {
                setIsLoading(false)
                goToFirstView({
                    level: AlertLevel.SUCCESS,
                    message: recoverPasswordResponse(200)
                })
            })
            .catch((err: IErrorResponse<Array<IMessageAndIsProperty | string>>) => {
                setIsLoading(false)
                if (err.body) {
                    const stringMessage: string = err.body[0] as string
                    setAlert({
                        level: AlertLevel.ERROR,
                        message: stringMessage ? translateMessage(stringMessage) : recoverPasswordResponse(err.status)
                    })
                } else {
                    setAlert({
                        level: AlertLevel.ERROR,
                        message: TextMessages.error.SERVER_NOT_AVAILABLE
                    })
                }
            });
    }

    /**
     * On Recover Password Cancel
     */
    const onRecoverPasswordCancel = (): void => {
        setAlert(null)
        goToFirstView()
    }

    /**
     * On Change Password Submit
     * @param data 
     */
    const onChangePasswordSubmit = (data: IChangePassword): void => {
        setAlert(null)
        setIsLoading(true)
        const newData: IChangePassword = {
            username: loginSubmitedUser ? loginSubmitedUser.username : '',
            password: loginSubmitedUser ? loginSubmitedUser.password : '',
            new_password: data.new_password
        }
        authService.changePassword(newData, props.customer)
            .then((result) => {
                setIsLoading(false)
                goToFirstView({
                    level: AlertLevel.SUCCESS,
                    message: changePasswordResponse(200)
                })
            })
            .catch((err: IErrorResponse<Array<string>>) => {
                setIsLoading(false)
                setAlert({
                    level: AlertLevel.ERROR,
                    message: err.body ? err.body : changePasswordResponse(err.status)
                })
            });
    }

    /**
     * On Change Password Cancel
     */
    const onChangePasswordCancel = (): void => {
        setAlert(null)
        goToFirstView()
    }

    /**
     * On PasswordWillExpire View Submit
     */
    const onPasswordWillExpireSubmit = (): void => {
        goToSecondView(SecondViewType.CHANGE_PASSWORD)
    }

    /**
     * Returns form content of the second view to render
     * @param type 
     * @returns 
     */
    const renderSecondPage = (type: SecondViewType) => {
        switch (type) {
            case SecondViewType.CHANGE_PASSWORD:
                return <ChangePasswordForm onSubmit={onChangePasswordSubmit} cancelChangePassword={onChangePasswordCancel} initForm={changePasswordForm} />
            case SecondViewType.RECOVER_PASSWORD:
                return <RecoverPasswordForm onSubmit={onRecoverPasswordSubmit} cancelRecoverPassword={onRecoverPasswordCancel} initForm={recoverPasswordForm} />
            case SecondViewType.PASSWORD_WILL_CHANGE:
                return <PasswordWillExpire onSubmit={onPasswordWillExpireSubmit} ignoreAlert={redirectToApplication} />
            default:
                return <ChangePasswordForm onSubmit={onChangePasswordSubmit} cancelChangePassword={onChangePasswordCancel} initForm={changePasswordForm} />;
        }
    }

    /**
     * Returns form title of the second view
     * @param type 
     * @returns 
     */
    const renderSecondPageTitle = (type: SecondViewType): string => {
        switch (type) {
            case SecondViewType.CHANGE_PASSWORD:
                return formHeaderText.resetPassword.title
            case SecondViewType.RECOVER_PASSWORD:
                return formHeaderText.passwordRecover.title
            case SecondViewType.PASSWORD_WILL_CHANGE:
                return formHeaderText.passwordWillExpire.title
            default:
                return formHeaderText.resetPassword.title
        }
    }

    /**
     * Returns form subtitle of the second view
     * @param type 
     * @returns 
     */
    const renderSecondPageSubTitle = (type: SecondViewType): string => {
        switch (type) {
            case SecondViewType.CHANGE_PASSWORD:
                return formHeaderText.resetPassword.subtitle ? formHeaderText.resetPassword.subtitle : ""
            case SecondViewType.RECOVER_PASSWORD:
                return formHeaderText.passwordRecover.subtitle ? formHeaderText.passwordRecover.subtitle : ""
            case SecondViewType.PASSWORD_WILL_CHANGE:
                return formHeaderText.passwordWillExpire.subtitle ? formHeaderText.passwordWillExpire.subtitle : ""
            default:
                return ""
        }
    }

    /**
     * Render ViewSlider View 
     * @param  
     */
    const RenderView: FC<{ index: number }> = ({ index }): ReactElement => (
        index === 1 ?
            <LoginForm onSubmit={onLoginSubmit} onRecoverPasswordClick={(trigger: boolean) => { goToSecondView(SecondViewType.RECOVER_PASSWORD, null) }} initForm={loginForm} />
            : renderSecondPage(secondPage)
    )

    /** Swipes to the Login Form (First View) */
    const goToFirstView = (alert?: IAlert<string>) => {
        setAlert(alert ? alert : null)
        setViewIndex(1)
    }

    /**
     * Swipes to the Recover or Change Password Form
     * @param mustChangePassword 
     */
    const goToSecondView = (secondPage: SecondViewType, alert?: IAlert<string> | null) => {
        setAlert(alert ? alert : null)
        setSecondPage(secondPage)
        setViewIndex(2)
    }

    /** VIEW RENDER */
    return (
        <div className={classes.homePage}>
            <div className={`${classes.formBox} ${isLoading ? classes.noTopBorder : ''}`}>
                <div className={classes["loading-div"]}>
                    <LoadingBar isLoading={isLoading} />
                </div>

                <div className={`${classes.header} ${isLoading || isViewDisabled ? classes['freeze-view'] : ''}`}>
                    <div className={classes["logo-container"]}>
                        <div className={classes["logitud_logo"]}></div>
                    </div>
                    <div className={classes.title}>
                        {
                            viewIndex === 1 ?
                                formHeaderText.connection.title :
                                renderSecondPageTitle(secondPage)
                        }
                    </div>
                    <div>
                        <p>
                            {
                                viewIndex === 1 ?
                                    formHeaderText.connection.subtitle :
                                    renderSecondPageSubTitle(secondPage)
                            }
                        </p>
                    </div>

                    {viewIndex === 2 && secondPage === SecondViewType.CHANGE_PASSWORD ?
                        <Label text={`${loginSubmitedUser ? loginSubmitedUser.username[0].toUpperCase() + loginSubmitedUser.username.slice(1) : ''}`} /> : null}
                </div>

                {alert ?
                    <div className={classes["alert-message"]}>
                        <Alert alert={alert} />
                    </div>
                    : null}

                <div className={`${classes.content} ${isLoading || isViewDisabled ? classes['freeze-view'] : ''}`}>
                    <ViewSlider
                        keepViewsMounted={false}
                        renderView={RenderView}
                        numViews={3}
                        activeView={viewIndex}
                        animateHeight={true}
                        transitionDuration={150}
                    />
                </div>
            </div>
            <LegalInfos />
        </div>
    )
}

export default HomePage