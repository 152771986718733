import React, { useEffect, useState, FC, ReactElement } from "react";
import { useForm } from "react-hook-form";
import { IRecoverPassword } from "../../interfaces/data-requests/recover-password.interface";

type ChildProps = {
    onSubmit: any,
    cancelRecoverPassword: any,
    initForm: IRecoverPassword
}

const RecoverPasswordForm: FC<ChildProps> = ({ onSubmit, cancelRecoverPassword, initForm }): ReactElement => {
    /**STATE */
    const { register, handleSubmit, errors, reset } = useForm();
    const [recoverPasswordForm, setRecoverPassword] = useState<IRecoverPassword>({
        username: ''
    })

    /**
     * Called on Init and when 'initForm' changes
     */
    useEffect(() => {
        reset(initForm)
        setRecoverPassword(initForm)
    }, [initForm, reset])

    /**
    * sets username form prop
     * @param e 
     */
    const handleUsernameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setRecoverPassword({ username: e.currentTarget.value })
    }

    /** VIEW RENDER */
    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="username">Nom d'utilisateur</label>
            <input
                id="username"
                type="text"
                aria-invalid={errors.username ? "true" : "false"}
                name="username"
                ref={register({ required: true })}
                onChange={handleUsernameChange}
            />
            {errors.username && (
                <div className="form-alerts" role="alert">
                    Nom d'utilisateur requis
                </div>
            )
            }
            <br />
            <div className="form-footer">
                <div className="left-side">
                    <button className="btn btn-link" type="button" onClick={cancelRecoverPassword}>Annuler</button>
                </div>
                <div className="right-side">
                    <button className="btn btn-primary" disabled={!recoverPasswordForm.username} type="submit">Valider</button>
                </div>
            </div>


        </form>
    )

}

export default RecoverPasswordForm
