import { IHeaderContent } from "../interfaces/form-header-text.interface";

export const formHeaderText: IHeaderContent = {
    connection: {
        title: "Connexion",
        subtitle: "Veuillez saisir vos identifiants"
    },
    passwordRecover: {
        title: "Récupération du mot de passe",
        subtitle: "Veuillez saisir votre nom d'utilisateur"
    },
    resetPassword: {
        title: "Changement du mot de passe",
        subtitle: "Veuillez changer votre mot de passe"
    },
    passwordWillExpire: {
        title: "Expiration de votre mot de passe"
    }
}