import { IChangePassword } from "../interfaces/data-requests/change-password.interface";
import { ILogin } from "../interfaces/data-requests/login.interface";
import { IRecoverPassword } from "../interfaces/data-requests/recover-password.interface";
import { IErrorResponse, IMessageAndIsProperty } from "../interfaces/server-response/error-response.interface";
import { ILoginSuccessResponse } from "../interfaces/server-response/success-response.interface";


export class AuthService {
    readonly SERVER_ROUTE: string = "auth-server";
    readonly PRODUCTION: boolean = process.env.REACT_APP_PRODUCTION === 'true' ? true : false
    readonly SERVER_HOST_URL?: string = process.env.REACT_APP_HOST_URL;
    readonly SERVER_DEV_HOST_URL?: string = 'https://services-dev.logitud.fr'
    /**
     * Login Method 
     * @param loginBody 
     * @param customer 
     */
    public login(loginBody: ILogin, customer: string): Promise<ILoginSuccessResponse> {
        if (this.PRODUCTION) {
            return new Promise((resolve, reject) => {
                fetch(`${this.SERVER_HOST_URL}/${customer}/${this.SERVER_ROUTE}/login`, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        "Content-type": "application/json",
                        "Accept": "application/json",
                        "Customer": customer
                    },
                    body: JSON.stringify(loginBody)
                })
                    .then((response: Response) => {
                        if (response.status === 200) {
                            return resolve(response.json());
                        } else {
                            response.json().then(resBody => {
                                const error: IErrorResponse<Array<IMessageAndIsProperty>> = {
                                    body: resBody,
                                    status: response.status
                                }
                                return reject(error);
                            }).catch(e => {
                                const error: IErrorResponse<null> = {
                                    body: null,
                                    status: response.status
                                }
                                return reject(error);
                            })
                        }
                    });
            });
        } else {
            return new Promise((resolve, reject) => {
                fetch(`${this.SERVER_DEV_HOST_URL}/${customer}/${this.SERVER_ROUTE}/login`, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        "Content-type": "application/json",
                        "Accept": "application/json",
                        "Customer": customer
                    },
                    body: JSON.stringify(loginBody)
                })
                    .then(() => {
                        fetch(`${this.SERVER_HOST_URL}/${customer}/${this.SERVER_ROUTE}/login`, {
                            credentials: 'include',
                            method: "POST",
                            headers: {
                                "Content-type": "application/json",
                                "Accept": "application/json",
                                "Customer": customer
                            },
                            body: JSON.stringify(loginBody)
                        })
                            .then((response: Response) => {
                                if (response.status === 200) {
                                    return resolve(response.json());
                                } else {
                                    response.json().then(resBody => {
                                        const error: IErrorResponse<Array<IMessageAndIsProperty>> = {
                                            body: resBody,
                                            status: response.status
                                        }
                                        return reject(error);
                                    }).catch(e => {
                                        const error: IErrorResponse<null> = {
                                            body: null,
                                            status: response.status
                                        }
                                        return reject(error);
                                    })
                                }
                            });

                    }).catch((error) => { return reject(error) });
            });
        }

    }


    /**
     * Logout Method
     * @param customer 
     */
    public logout(customer: string): Promise<any> {
        if (this.PRODUCTION) {
            return new Promise((resolve, reject) => {
                fetch(`${this.SERVER_HOST_URL}/${customer}/${this.SERVER_ROUTE}/logout`, {
                    credentials: 'include',
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                        "Accept": "application/json"
                    }
                }).then((response: Response) => {
                    if (response.status === 200) {
                        return resolve(response.text());
                    } else {
                        response.json().then(resBody => {
                            return reject(response.status);
                        }).catch(e => {
                            return reject(response.status);
                        })
                    }
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                fetch(`${this.SERVER_DEV_HOST_URL}/${customer}/${this.SERVER_ROUTE}/logout`, {
                    credentials: 'include',
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                        "Accept": "application/json"
                    }
                }).then(() => {
                    fetch(`${this.SERVER_HOST_URL}/${customer}/${this.SERVER_ROUTE}/logout`, {
                        credentials: 'include',
                        method: "GET",
                        headers: {
                            "Content-type": "application/json",
                            "Accept": "application/json"
                        }
                    }).then((response: Response) => {
                        if (response.status === 200) {
                            return resolve(response.text());
                        } else {
                            response.json().then(resBody => {
                                return reject(response.status);
                            }).catch(e => {
                                return reject(response.status);
                            })
                        }
                    });
                })
            })
        }
    }

    /**
     * Change Password
     * @param changePasswordBody 
     * @param customer 
     */
    public changePassword(changePasswordBody: IChangePassword, customer: string): Promise<string> {
        return new Promise((resolve, reject) => {
            fetch(`${this.SERVER_HOST_URL}/${customer}/${this.SERVER_ROUTE}/password/change`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    "Accept": "application/json",
                    "Customer": customer
                },
                body: JSON.stringify(changePasswordBody)
            })
                .then((response: Response) => {
                    if (response.status === 200) {
                        return resolve(response.text());
                    } else {
                        response.json().then(resBody => {
                            const error: IErrorResponse<Array<IMessageAndIsProperty>> = {
                                body: resBody,
                                status: response.status
                            }
                            return reject(error);
                        }).catch(e => {
                            const error: IErrorResponse<null> = {
                                body: null,
                                status: response.status
                            }
                            return reject(error);
                        })
                    }
                });
        })
    }

    /**
     * Recover Password
     * @param recoverPasswordBody 
     * @param customer 
     */
    public recoverPassword(recoverPasswordBody: IRecoverPassword, customer: string): Promise<string | Array<string>> {
        return new Promise((resolve, reject) => {
            fetch(`${this.SERVER_HOST_URL}/${customer}/${this.SERVER_ROUTE}/password/recover`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    "Accept": "application/json",
                    "Customer": customer
                },
                body: JSON.stringify(recoverPasswordBody)
            })
                .then((response: Response) => {
                    if (response.status === 200) {
                        return resolve(response.text());
                    } else {
                        response.json().then(resBody => {
                            const error: IErrorResponse<Array<IMessageAndIsProperty>> = {
                                body: resBody,
                                status: response.status
                            }
                            return reject(error);
                        }).catch(e => {
                            const error: IErrorResponse<null> = {
                                body: null,
                                status: response.status
                            }
                            return reject(error);
                        })
                    }
                });
        });
    }
}