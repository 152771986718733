import { TextMessages } from "../variables/text-messages";

export function changePasswordResponse(status: number): string {
    let message: string;
    switch (status) {
        case 0:
            message = TextMessages.error.SERVER_NOT_AVAILABLE;
            break;
        case 200:
            message = TextMessages.success.SUCCESS_ON_PASWORD_CHANGE;
            break;
        case 401:
            message = TextMessages.error.NOT_AUTHORIZED_ACTION;
            break;
        case 409:
            message = TextMessages.error.PASSWORD_CHANGE_FAIL;
            break;
        default:
            message = TextMessages.error.PASSWORD_CHANGE_FAIL;
    }
    return message
}