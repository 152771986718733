import { FC, ReactElement, useEffect } from "react"
import classes from './label.module.css'
import { PersonIcon } from '@primer/octicons-react'

type ChildProps = {
    text: string;
}

const Label: FC<ChildProps> = ({ text }): ReactElement => {

    /**
     * Called on Init
     */
    useEffect(() => {
    }, [])

    return (
        <div className={classes.label}>
            <PersonIcon size={13} className={classes.avatar} /> {text}
        </div>
    )
}

export default Label