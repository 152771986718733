import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, RouteComponentProps } from 'react-router-dom';
import routes from './routing/routes';

/** Application Variables */
const baseHref = document.location.pathname.substring(0, document.location.pathname.indexOf('/signin') + 8)
const customer = baseHref.split('/')[1];
const appName = baseHref.split('/')[baseHref.split('/').indexOf('signin') - 1];

const Application: React.FunctionComponent<{}> = props => {
    useEffect(() => {
    }, [])

    return (
        <BrowserRouter basename={baseHref}>
            <Switch>
                {routes.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            render={(props: RouteComponentProps<any>) => (
                                <route.component
                                    {...props}
                                    {...route.props}
                                    baseHref={baseHref}
                                    customer={customer}
                                    appName={appName}
                                />
                            )}
                        />
                    )
                })}
            </Switch>
        </BrowserRouter>
    );
}

export default Application
